import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { languageTypes } from './language.service.interface';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private translateService: TranslocoService) {}
  /**
   * Change the language in which application to be translated
   * @remarks
   * The language here is like `en` or `de`.
   * @param language - The selected language
   */
  changeLanguage(language: languageTypes): void {
    localStorage.setItem('manuallySetLanguage', language);
    this.translateService.setActiveLang(language || 'en');
  }

  /**
   * Get selected language
   * @returns Selected language
   */
  getLanguage(): string {
    return localStorage.getItem('manuallySetLanguage') || 'en';
  }
}
